const thirdPartyScriptsSetUp = ({
  gtmId,
  auth = '',
  preview = '',
  dataLayerName = 'dataLayer',
  events = {}
}) => {
  const createAsyncScript = () => {
    const script = document.createElement('script');
    script.type = 'text/partytown';
    script.async = true;

    return script;
  };

  const gtmScript = createAsyncScript();
  gtmScript.innerHTML = '\n\t(function(w,d,s,l,i){\n\t\tw[l]=w[l]||[];\n\t\tw[l].push({\'gtm.start\': new Date().getTime(),event:\'gtm.js\', ' + JSON.stringify(events).slice(1, -1) + '});\n\t\tvar f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!=\'dataLayer\'?\'&l=\'+l:\'\';\n\t\tj.async=true;j.src=\'https://www.googletagmanager.com/gtm.js?id=\'+i+dl+\'' + auth + preview + '&gtm_cookies_win=x\';\n\t\tf.parentNode.insertBefore(j,f);\n\t\t})(window,document,\'script\',\'' + dataLayerName + '\',\'' + gtmId + '\');';

  const datalayerScript = createAsyncScript();
  datalayerScript.innerHTML = `window.dataLayer = window.dataLayer || []; window.gtag = function gtag(){window.dataLayer.push(arguments);}; gtag('js', new Date()); gtag('config', '${gtmId}');`;

  const gtmNoscript =  document.createElement('noscript');
  gtmNoscript.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=${gtmId}${auth}${preview}&gtm_cookies_win=x" height="0" width="0" style="display:none;visibility:hidden" id="tag-manager"></iframe>`;
  
  return { headScripts: [gtmScript, datalayerScript], body: [gtmNoscript] };
};

export default thirdPartyScriptsSetUp;