import React, { useEffect } from 'react';
import { Placeholder, VisitorIdentification, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import Helmet from 'react-helmet';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import CookieBanner from './components/CookieBanner';
import i18n from 'i18next';
import thirdPartyScriptsSetUp from '@/js/utils/thirdPartyScripts';
import { Partytown } from '@builder.io/partytown/react';

const Layout = ({ sitecoreContext, route }) => {
  const templateClassName = route.templateName.toLowerCase().split(/\s+/).join('-');

  const title = sitecoreContext?.seo?.title;
  const description = sitecoreContext?.seo?.description;
  const siteLanguage = sitecoreContext?.language || 'en';
  const recaptchaKey = sitecoreContext?.reCaptcha?.googleRecaptchaKey || '';
  
  useEffect(() => {
    const { headScripts, body } = thirdPartyScriptsSetUp(
      { gtmId: i18n.t('CommonSettingsGoogleTagManagerID') }
    );
    document.head.append(...headScripts);
    document.body.prepend(...body);
  }, [])  

  return (
    <React.Fragment>
      {/* react-helmet enables setting <head> contents, like title and OG meta tags */}
      <Helmet defer={false}>
        <title>{title || 'Page'}</title>
        <meta name="description" content={description || ''} />
        <meta name="keywords" content={sitecoreContext?.seo?.keywords || ''} />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={sitecoreContext.route?.fields?.PreviewTitle?.value || title} />
        <meta name="twitter:description" content={sitecoreContext.route?.fields?.PreviewText?.value || description} />
        <meta name="twitter:image" content={sitecoreContext.route?.fields?.PreviewImage?.value?.src} />

        <meta property="og:title" content={sitecoreContext.route?.fields?.PreviewTitle?.value || title} />
        <meta property="og:description" content={sitecoreContext.route?.fields?.PreviewText?.value || description} />
        <meta property="og:image" content={sitecoreContext.route?.fields?.PreviewImage?.value?.src} />
        {route?.fields?.AllowGoogleIndexing?.value === false && <meta name="robots" content="noindex, nofollow" />}
      </Helmet>
      {/*Partytown loads and execute 3-d party scripts with web worker without blocking  main thread*/}
      { <Partytown
        debug={false}
        logCalls={false}
        forward={['dataLayer.push', 'gtag']}
        resolveUrl={(url) => {
          const proxyDomains = [
            'www.google-analytics.com',
            'snap.licdn.com',
            'googleads.g.doubleclick.net',
            'www.gstatic.com'
          ]
          if (proxyDomains.includes(url.hostname)) {
            const proxyUrl = new URL(`${window.location.origin}/proxy`);
            proxyUrl.searchParams.append('url', url.href);
            return proxyUrl;
          }
          return url;
        }}
      /> }
      <VisitorIdentification />
      <div className={`site-wrap${sitecoreContext?.pageEditing ? ' experience-editor' : ''} ${templateClassName}`}>
        <GoogleReCaptchaProvider reCaptchaKey={recaptchaKey} language={siteLanguage}>
          <Placeholder name="jss-main" rendering={route} />
          <Placeholder name="jss-modals" rendering={route} />
          <CookieBanner />
        </GoogleReCaptchaProvider>
      </div>
    </React.Fragment>
  );
};

export default withSitecoreContext()(Layout);
